import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ListGroup, Modal, Button } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import Axios from 'axios'

function App() 
{
  const isMounted = useRef(false);
  const [tvnTop, setTvnTop] = useState('');
  const [tvpTop, setTvpTop] = useState('');
  const [tvn, setTvn] = useState([]);
  const [tvp, setTvp] = useState([]);

  const [date, setDate] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => 
  {
    getDate();
  }, []);

  useEffect (() =>
  {
    if (isMounted.current)
    {
      getData();
    }
    else
    {
      isMounted.current = true;
    }
    
  }, [date]);

  const getData = async () =>
  {
    setIsLoading(true);
    await getTvn();
    await getTvp();
    setIsLoading(false);
  }

  const getDate = () =>
  {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setDate(`${yesterday.getFullYear()}/${yesterday.getMonth() < 9 ? '0' + (yesterday.getMonth() + 1) : (yesterday.getMonth() + 1)}/${yesterday.getDate() < 10 ? '0' + yesterday.getDate() : yesterday.getDate()}`);
  }

  const getTvn = async () =>
  {
    if (tvn.length > 0)
    {
      setTvn([])
    }
    const { data } = await Axios.get(`https://tvtapi.deta.dev/get-by-date/tvn/${date}`);

    for (let i = 0; i < data.tvn.length; i++)
    {
      if (data.tvn[i].type === "top-story")
      {
        setTvnTop(data.tvn[i].headline)
      }
      else
      {
        setTvn(tvn => [...tvn, data.tvn[i].headline]);
      }
    }
  }

  const getTvp = async () =>
  {
    if (tvn.length > 0)
    {
      setTvp([])
    }

    const { data } = await Axios.get(`https://tvtapi.deta.dev/get-by-date/tvp/${date}`);

    for (let i = 0; i < data.tvp.length; i++)
    {
      if (data.tvp[i].type === "top-story")
      {
        setTvpTop(data.tvp[i].headline)
      }
      else
      {
        setTvp(tvp => [...tvp, data.tvp[i].headline]);
      }
    }
  }
  if (isLoading)
  {
    return (
      <div id="App">
        <div id="wrapper">
          <div id="loading">
            wczytywanie...
          </div>
        </div>
      </div>
    );
  }
  else
  {
    return (
      <div id="App">
        <div id="header">
          TVN vs TVP - nagłówki <Button variant="dark" size="lg" onClick={() => {setShowModal(true)}}>{date}</Button>
        </div>
        <Modal show={showModal} onHide={() => {setShowModal(false)}}>
          <Modal.Header closeButton>
            <Modal.Title>Zmień datę</Modal.Title>
          </Modal.Header>
          <Modal.Body>Tu sie będzie zmieniać datę</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {setShowModal(false)}}>
              Zamknij
            </Button>
            <Button variant="primary" onClick={() => {setShowModal(false)}}>
              Zapisz
            </Button>
          </Modal.Footer>
        </Modal>
        <div id="content">
          <div id="left" className="column">
            <ListGroup>
              <ListGroup.Item active>TVN</ListGroup.Item>
              <ListGroup.Item variant="primary">{tvnTop}</ListGroup.Item>
              {tvn.map(el => <ListGroup.Item>{el}</ListGroup.Item>)}
            </ListGroup>
          </div>
          <div id="right" className="column">
            <ListGroup>
              <ListGroup.Item active>TVP</ListGroup.Item>
              <ListGroup.Item variant="primary">{tvpTop}</ListGroup.Item>
              {tvp.map(el => <ListGroup.Item>{el}</ListGroup.Item>)}
            </ListGroup>
          </div>
        </div>
        <div id="footer">
          <div class="custom-shape-divider-top-1644602852">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
          </div>
          <p>
            <a href="http://koczulap.pl">www</a> <a href="http://github.com/dcase808">github</a><br/>
            all data diplayed on this site is property of <a href="http://tvn24.pl">tvn24</a> and <a href="http://tvp.info">tvp info</a></p>
        </div>
      </div>
    );
  }
}

export default App;